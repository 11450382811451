import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import './Button.scss';

const Button = ({ to, onClick, children, className, red = false }) => {
  return (
    <>
      {to ?
        <Link to={to} className={cn('button', className, { 'button-red': red })}>{children}</Link>
        :
        <div onClick={onClick} className={cn('button', className)}>{children}</div>
      }
    </>
  );
};

export default Button;
